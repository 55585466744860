import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import MenuItem from '@mui/material/MenuItem';

import CompanyLogo from '../../../assets/DashboardIcons/smartsaLogo.svg';
import { ReactComponent as SmartsaIcon } from '../../../assets/auth-icons/smartsa_icon.svg'
import { ReactComponent as AboutUsIcon } from '../../../assets/DashboardIcons/AboutUsIcon.svg';
import { ReactComponent as ContactUsIcon } from '../../../assets/DashboardIcons/ContactUsIcon.svg';
import { ReactComponent as HowItWorksIcon } from '../../../assets/DashboardIcons/HowItWorksIcon.svg';
import { ReactComponent as LogOutIcon } from '../../../assets/DashboardIcons/LogOutIcon.svg';
import { ReactComponent as MyAccountIcon } from '../../../assets/DashboardIcons/MyAccountIcon.svg';
import { ReactComponent as ProfileIcon } from '../../../assets/DashboardIcons/ProfileIcon.svg';
import axios from 'axios';

// const settings = ['ProfileIcon', 'Account', 'Dashboard', 'Logout'];
// const settings = [
//   {name: 'Hi' + (teacherDetail[0]?.username) , icon: <SmartsaIcon style={{width: '1.227vw', height: '2.051vw'}} />, path: '/'},
//   { name: 'Profile', icon: <ProfileIcon />, path: '/teacherprofile' },
//   { name: 'My Account', icon: <MyAccountIcon />, path: '/accountdetails' },
//   { name: 'About Us', icon: <AboutUsIcon />, path: '/about-us' },
//   { name: 'How It Works', icon: <HowItWorksIcon />, path: '/how-it-works' },
//   { name: 'Contact Us', icon: <ContactUsIcon />, path: '/help' },
//   { name: 'LogOut', icon: <LogOutIcon />, path: '/login' }
// ]

function Navbar({ setTemp = () => { } }) {
  /* Getting Institute/Teacher Name */
  const [teacherDetail, setTeacherDetail] = useState({});
  useEffect(() => {
    const fetchData = async () => {
      try {
        const institute_id = localStorage.getItem('institute_id');
        if (institute_id) {
          const resTeacherDetails = await axios.get(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_KEY}/teacherprofileedit/${institute_id}`);
          setTeacherDetail(resTeacherDetails.data[0]);
          console.log(resTeacherDetails.data[0]);
        }
      } catch (error) {
        console.log(error);
        return error;
      }
    }
    fetchData();
  }, []);

  const settings = [
    { name: 'Hi, ' + (teacherDetail?.institute_name), icon: <SmartsaIcon style={{ width: '1.227vw', height: '2.051vw' }} />, path: '/' },
    { name: 'Profile', icon: <ProfileIcon />, path: '/teacherprofile' },
    { name: 'My Account', icon: <MyAccountIcon />, path: '/accountdetails' },
    { name: 'About Us', icon: <AboutUsIcon />, path: '/about-us' },
    { name: 'How It Works', icon: <HowItWorksIcon />, path: '/how-it-works' },
    { name: 'Contact Us', icon: <ContactUsIcon />, path: '/help' },
    { name: 'LogOut', icon: <LogOutIcon />, path: '/login' }
  ]

  useEffect(() => {
    setTemp(teacherDetail);
  },
    [teacherDetail]);


  // const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(false);

  // const handleOpenNavMenu = (event) => {
  //   setAnchorElNav(event.currentTarget);
  // };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  // const handleCloseNavMenu = () => {
  //   setAnchorElNav(null);
  // };

  const navigate = useNavigate();
  const handleCloseUserMenu = () => {
    setAnchorElUser(false);
  };

  const handleNavigateMenu = (path) => {
    if(path === "/login") localStorage.clear();
    navigate(path);
  };

  return (
    <AppBar position="static" sx={{ background: '#EAF1F7', borderRadius: '8px' }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters sx={{ display: 'flex', justifyContent: 'space-between' }}>
          {/* <AdbIcon  /> */}
          <a href="/dashboard">
            <img
              src={CompanyLogo}
              alt="Company Logo"
              style={{ display: 'flex', marginRight: '16px', height: '36px', width: '197.56px' }}
            />
          </a>
          <Box sx={{ flexGrow: 0 }}>
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <Avatar alt="Remy Sharp" src="../assets/userLogo.svg" />   {/* svg file not showing up ! */}
              <span style={{ fontFamily: 'Roboto', fontSize: '18px', color: 'black', marginLeft: '5px', fontWeight: 600 }}>Hi, {teacherDetail?.institute_name}</span>
            </IconButton>
            <Menu
              sx={{ mt: '45px', position: 'absolute' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={anchorElUser}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting, idx) => (
                <MenuItem key={idx} onClick={() => { handleNavigateMenu(setting.path) }}
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between'
                  }}>
                  {setting.icon}
                  <Typography textAlign="center">{setting.name}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>

  );
}
export default Navbar;
