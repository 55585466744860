import React, { useEffect, useState } from 'react'
import Wrapper from '../WrapperDesktop/Wrapper'
import SidenavRight from '../Common/FilterSidenav/SidenavRight'
import styles from './StudentList.module.css'
import { ReactComponent as GreaterThanIcon } from '../../assets/DashboardIcons/GreaterThanIcon.svg'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { TablePagination } from '@mui/material'

const StudentList = () => {
  // let currentItemsToShow = [];
  // const [currentPage, setCurrentPage] = useState(1);
  // const [totalItems, setTotalItems] = useState();
  // const [indexOfFirstItem, setIndexOfFirstItem] = useState(0);
  // const [indexOfLastItem, setIndexOfLastItem] = useState(12);
  const [studentsList, setStudentsList] = useState([]);
  const [totalStudentList, setTotalStudentList] = useState([]);
  // const [options, setOptions] = useState([])
  const institute_id = localStorage.getItem('institute_id');
  useEffect(() => {
    const fetchData = async () => {
      const resStudentsList = await axios.get(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_KEY}/studentlist/${institute_id}`);
      setStudentsList(resStudentsList.data);
      console.log(resStudentsList.data);

      /* Pagination----------------------------- */
      // ItemsPerPage = 12 (required)
      // const itemsPerPage = 12;
      // setTotalItems(resStudentsList.data.length);
      // setIndexOfLastItem(currentPage * itemsPerPage);
      // setIndexOfLastItem(indexOfLastItem > totalItems ? totalItems : indexOfLastItem);
      // setIndexOfFirstItem(indexOfLastItem - itemsPerPage);
      // // console.log(indexOfFirstItem, indexOfLastItem, "Hello World");
      // setStudentsList((resStudentsList.data).slice(indexOfFirstItem, indexOfLastItem));
      setTotalStudentList(resStudentsList.data);
      // console.log(resStudentsList.data);
    }
    fetchData();
  }, []);


  // const handleStudentListDisplay = (value) => {
  //   setStudentsList((totalStudentList).slice(0, value));
  //   setIndexOfLastItem(value);
  //   console.log(value);
  // }

  // const handleLeftShift = () => {
  //   setStudentsList((totalStudentList).slice(0, indexOfLastItem - 12 > 0 ? indexOfLastItem - 12 : indexOfLastItem));
  //   // console.log((indexOfLastItem - 12 > 0 && indexOfLastItem - 12));
  //   setIndexOfLastItem(indexOfLastItem - 12 > 0 ? indexOfLastItem - 12 : indexOfLastItem);
  // }
  // const handleRightShift = () => {
  //   setStudentsList((totalStudentList).slice(0, indexOfLastItem + 12 > totalItems ? totalItems : indexOfLastItem + 12));
  //   console.log(totalItems, indexOfLastItem + 12, indexOfLastItem + 12 > totalItems ? totalItems : indexOfLastItem + 12);
  //   setIndexOfLastItem(indexOfLastItem + 12 > totalItems ? totalItems : indexOfLastItem + 12);
  // }

  /* Pagination | MUI */
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(12);
  const handleChangePage = (e, newPage) => {
    setPage(newPage);
  }
  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(+e.target.value);
    setPage(0);
  }
  const handleTimeFormat = (time) => {
    const timeSplit = time?.split(':');
    console.log(timeSplit);
    return timeSplit ? `${parseInt(timeSplit[0])}h ${parseInt(timeSplit[1])}m ${parseInt(timeSplit[2])}s` : '0h 0m 0s';
  }
  return (
    <>
      <Wrapper />
      <div style={{
        display: 'flex',
        justifyContent: 'space-between',
        margin: '40px 300px 40px 132px',
        alignItems: 'center',
        fontSize: '24px',
        fontWeight: '500',

      }}>
        <div>Dashboard</div>
        <>
          <TablePagination
            component="div"
            rowsPerPageOptions={[12, 24, 36]}
            count={studentsList.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          {/* <div>
            <label htmlFor="cars" style={{ fontSize: '14px', marginRight: '8px' }}>Rows per page</label>
            <select onChange={(e) => handleStudentListDisplay(e.target.value)} className={styles.DropDownIcon} name="pages" id="pages" style={{ width: '56px', borderRadius: '4px', padding: '8px', cursor: 'pointer' }}>
              {
                
                new Array(3).fill(null).map((_,ind)=>{
                  // options.push(
                    return (<option key={(ind+1)*12} value={(ind+1)*12} >
                      {(ind+1)*12}
                    </option>)
                  // )
                })
              }
                
              
            </select>
          </div>
          <div className={styles.pagesStatus}>
            <div>
              {indexOfFirstItem + 1}- {indexOfLastItem} of {totalStudentList.length}
            </div>
            <div>
              <button onClick={() => handleLeftShift()}>&lt;</button>
              <button onClick={() => handleRightShift()}>&gt;</button>
            </div>
          </div> */}
        </>
      </div>

      <div className={styles.displayTable}>
        <div className={styles.heading}>
          <div>Name</div>
          <div className={styles.headingLeft}>
            <div>Class</div>
            <div style={{ textWrap: 'wrap', width: '4.6rem' }}>Overall Avg. Time/Day</div>
            <div style={{ textWrap: 'wrap', width: '5rem' }}>Last Week Avg. Time/Day</div>
            <div>Last Active</div>
            <div>Status</div>
            <div />
          </div>
        </div>
        <div className={styles.innerBoard} style={{height: rowsPerPage <= 12 ? 'auto': '42vw', overflowY: rowsPerPage > 12 && 'auto'}}>
          {
            studentsList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((student, index) => (
                <Link to={`/studentprofile/${student.user_id}`} style={{ textDecoration: 'none', color: 'black' }} key={student.user_id}>
                  <div className={styles.row}>
                    <div>
                      {student.name}
                    </div>
                    <div className={styles.rowRight}>
                      <div style={{}}>{student.user_class}</div>
                      <div>{handleTimeFormat(student.avg_time_per_day)}</div>
                      <div>{handleTimeFormat(student.last_week_avg_time_per_day)}</div>  {/* 0h 19m 48s */}
                      <div style={{width: '2.8rem'}}>{student.last_active_date === null ? 0 : student.last_active_date} days</div>
                      <div style={{ 
                        color: student.status === 'Critical' ? 'rgb(229 61 68)' :
                        student.status === 'OutStanding' ? 'rgb(60, 147, 19)' : 'rgb(19, 53, 147)',
                        width: '3rem'
                      }}>
                        {student.status}
                      </div>
                      <GreaterThanIcon className={styles.greaterThan} />
                    </div>
                  </div>
                </Link>
              ))
          }
        </div>
      </div>
      <div className={styles.legendsParameter}>
        <div className={styles.legend}>
          <div style={{ backgroundColor: 'rgba(229, 61, 68, 1)', width: '10px', height: '10px' }}></div>
          <div>Critical</div>
        </div>
        <div className={styles.legend}>
          <div style={{ backgroundColor: 'rgba(19, 53, 147, 1)', width: '10px', height: '10px' }}></div>
          <div>Watchful</div>
        </div>
        <div className={styles.legend}>
          <div style={{ backgroundColor: 'rgba(60, 147, 19, 1)', width: '10px', height: '10px' }}></div>
          <div>Outstanding</div>
        </div>
      </div>

      <SidenavRight studentsList={totalStudentList} setStudentsList={setStudentsList} />
    </>
  )
}

export default StudentList