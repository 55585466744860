import React, { useEffect, useState } from 'react'
import styles from './Analytics.module.css'
import { ReactComponent as GreaterThanIcon } from '../../../assets/DashboardIcons/GreaterThanIcon.svg'
import axios from 'axios';

const Analytics = ({day, user_id}) => {
  /* Analytics BackEnd */
  const [todayAttemptSummary, setTodayAttemptSummary] = useState([]);
  const [previousAttemptSummary, setPreviousAttemptSummary] = useState([]);
  const [previousAttemptSummary1, setPreviousAttemptSummary1] = useState([]);
  const [previousAttemptSummary2, setPreviousAttemptSummary2] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const resTodayAttemptSummary = await axios(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_KEY}/todayattemptsummary/${user_id}`);
      const resPreviousAttemptSummary = await axios(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_KEY}/previousattemptsummary/${user_id}`);
      const resPreviousAttemptSummary2 = await axios(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_KEY}/previousattemptsummarytooltipbest/${user_id}`);
      const resPreviousAttemptSummary1 = await axios(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_KEY}/previousattemptsummarytooltiplast/${user_id}`);
      setTodayAttemptSummary(resTodayAttemptSummary.data);
      setPreviousAttemptSummary(resPreviousAttemptSummary.data);
      setPreviousAttemptSummary1(resPreviousAttemptSummary1.data);
      setPreviousAttemptSummary2(resPreviousAttemptSummary2.data);
    }
    fetchData();
  }, [user_id]);
  /* Last in Last Active Tooltip */
  const handleLastScore = (id) => {
    const mission = previousAttemptSummary1.find(mission => mission.mission_id === id);
    return mission?.total_correct;
  }
  const handleTotalLastScore = (id) => {
    const mission = previousAttemptSummary1.find(mission => mission.mission_id === id);
    return mission?.total_questions;
  }
  const handleTimeElapsedLastActive = (id) => {
    const mission = previousAttemptSummary1.find(mission => mission.mission_id === id);
    let time = mission?.elapsed_time.split(':');
    return `${time[0]}h ${time[1]}m ${time[2]}s`;
  }
  /* Best in Last Active Tooltip */
  const handleBestScore = (id) => {
    const mission = previousAttemptSummary2.find(mission => mission.mission_id === id);
    console.log("hello", mission)
    return mission?.max_total_correct;
  }
  const handleTotalBestScore = (id) => {
    const mission = previousAttemptSummary2.find(mission => mission.mission_id === id);
    return mission?.total_questions;
  }
  const handleTimeElapsedBest = (id) => {
    const mission = previousAttemptSummary2.find(mission => mission.mission_id === id);
    let time = mission?.min_elapsed_time.split(':');
    return `${time[0]}h ${time[1]}m ${time[2]}s`;
    // return mission?.min_elapsed_time;
  }
  const handleTimeFormat = (time) => {
    const temp = time?.split(':');
    return `${temp[0]}h ${temp[1]}m ${temp[2]}s`;
  }

  const [todayCardData, settodayCardData] = useState();
  return (
    <div className={styles.analyticsBoard}>
      <div>{todayCardData}</div>
      <div className={styles.overlay}></div>
      <div style={{ fontSize: '20px', marginBottom: '16px' }}>Attempt Summary</div>
      <div className={styles.heading}>
        <div>Mission</div>
        <div className={styles.headingLeft}>
          <div style={{ textWrap: 'wrap' }}>{day === 'Today' ? 'Today' : 'Last Active Day'}</div>
          <div>Total</div>
          <div>Valid</div>
          <div />
        </div>
      </div>
      <div className={styles.innerBoard}>
        {
          (day !== 'Today' ? previousAttemptSummary : todayAttemptSummary).map((mission, index) => (
            <div className={styles.row} key={index}>
              <div>
                {day === 'Today' ? mission.mission_name : mission.mission_name}
                <svg className={styles.svgStyle} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.99947 2.76704e-06C9.31212 -0.00314381 11.9968 2.67774 12.0024 5.99423C12.008 9.31072 9.31666 12.0042 5.99947 12C4.40831 12 2.88232 11.3679 1.7572 10.2428C0.632085 9.11767 0 7.59168 0 6.00052C0 4.40936 0.632085 2.88338 1.7572 1.75826C2.88232 0.633137 4.40831 0.00105165 5.99947 0.00105165V2.76704e-06ZM5.99947 0.837342C4.97878 0.837688 3.9811 1.14061 3.13253 1.70783C2.28396 2.27505 1.62258 3.08109 1.232 4.02409C0.841414 4.9671 0.739151 6.00472 0.938134 7.00583C1.13712 8.00694 1.62841 8.92659 2.34993 9.64854C3.07145 10.3705 3.9908 10.8624 4.99178 11.0619C5.99277 11.2615 7.03046 11.1599 7.9737 10.7699C8.91694 10.3799 9.72339 9.71901 10.2911 8.87078C10.8589 8.02255 11.1624 7.02506 11.1634 6.00437C11.1645 5.32588 11.0317 4.65383 10.7727 4.02675C10.5136 3.39966 10.1333 2.82986 9.65366 2.35002C9.17398 1.87017 8.60431 1.48971 7.97731 1.23043C7.35031 0.971157 6.67831 0.838169 5.99982 0.83909L5.99947 0.837342Z" fill="black" />
                  <path d="M6.42062 7.11934C6.42062 7.65985 6.42062 8.20048 6.42062 8.74122C6.42062 9.01847 6.24931 9.21041 6.00353 9.21146C5.75775 9.21251 5.58398 9.01812 5.58398 8.74367C5.58398 7.65775 5.58398 6.57195 5.58398 5.48626C5.58398 5.20901 5.75565 5.02407 6.00737 5.02651C6.25211 5.02896 6.42027 5.21426 6.42062 5.48417C6.42156 6.02911 6.42156 6.57416 6.42062 7.11934Z" fill="black" />
                  <path d="M6.55668 3.76436C6.5564 3.874 6.52367 3.98111 6.4626 4.07218C6.40153 4.16324 6.31487 4.23418 6.21354 4.27606C6.11221 4.31794 6.00075 4.32888 5.89321 4.30749C5.78567 4.28611 5.68687 4.23337 5.60927 4.15591C5.53167 4.07845 5.47874 3.97975 5.45715 3.87226C5.43557 3.76476 5.4463 3.65328 5.48798 3.55187C5.52967 3.45046 5.60045 3.36366 5.6914 3.30243C5.78235 3.24119 5.88939 3.20825 5.99904 3.20777C6.07226 3.20749 6.14481 3.22169 6.21252 3.24955C6.28023 3.27741 6.34177 3.31838 6.39359 3.37011C6.44542 3.42184 6.4865 3.4833 6.51449 3.55096C6.54248 3.61862 6.55682 3.69114 6.55668 3.76436Z" fill="black" />
                </svg>
                <div className={styles.toolTipStyle1}>
                  <div>No. of Ques.: {day === 'Today' ? mission.max_questions : mission.max_questions}</div>
                  <div>Total Time: {handleTimeFormat(mission.speed)}</div>
                </div>
              </div>
              <div className={styles.rowRight}>
                <div>{mission.attempt_count}</div>
                <div>{mission.total_attempts}</div>
                <div>{mission.valid_attempts}</div>
                <GreaterThanIcon className={styles.greaterThan} />
                <div className={styles.tooltipStyle}>
                  <table className={styles.tableStyle}>
                    <tr>
                      <td className={styles.label}>Last:</td>
                      <td className={styles.label}>Best:</td>
                    </tr>
                    <tr>
                      <td>
                        Score: {day === 'Today' ? mission.last_score : handleLastScore(mission.mission_id)}/{day == 'Today' ? mission.max_questions : handleTotalLastScore(mission.mission_id)}<br />
                        Time: {day === 'Today' ? handleTimeFormat(mission.last_time) : handleTimeElapsedLastActive(mission.mission_id)}
                      </td>
                      <td>
                        Score: {day === 'Today' ? mission.best_score : handleBestScore(mission.mission_id)}/{day == 'Today' ? mission.max_questions : handleTotalBestScore(mission.mission_id)}<br />
                        Time: {day === 'Today' ? handleTimeFormat(mission.best_time) : handleTimeElapsedBest(mission.mission_id)}
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          ))
        }
   
      </div>
    </div>

  )
}

export default Analytics;