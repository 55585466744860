import React, { useEffect, useState } from 'react';
import Navbar from '../Common/NavBars/Navbar';
import SidenavLeft from '../Common/NavBars/SidenavLeft';
import TimelineSidenavRight from '../../components/Timeline/TimelineSidenavRight'

/* Styles */
import styles from './Analytics.module.css';

/* Icons */
import { ReactComponent as Earnings } from '../../assets/AnalyticsIcons/EarningIcon.svg';
import { ReactComponent as RegistersIcon } from '../../assets/AnalyticsIcons/RegistersIcon.svg'
/* ChartJS & react-chart-2 */
import { Doughnut, Bar, Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  ArcElement,
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend
} from 'chart.js';
import axios from 'axios';
import { useParams } from 'react-router-dom';
ChartJS.register(
  ArcElement,
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend
);

const Analytics = () => {
  const institute_id = localStorage.getItem('institute_id');
  const [analytics, setAnalytics] = useState({});
  
  /* Active & NonActive */
  const [activeNonActive, setActiveNonActive] = useState();

  /* Filters */
  const [trackNavbarFilter, setTrackNavbarFilter] = useState([]);
  console.log(trackNavbarFilter, " analytics filter track");
  useEffect(() => {
    const fetchAnalyticsData = async () => {
      const resAnalyticsData = await axios.get(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_KEY}/analytics/${institute_id}`);
      setAnalytics(resAnalyticsData.data);
console.log(resAnalyticsData.data, " got the analytics DB");
      /* Active & NonActive */
      const resActiveNonActive = await axios.get(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_KEY}/analyticsfilteractivenonactive/${institute_id}`);
      setActiveNonActive(resActiveNonActive.data);
      console.log(resActiveNonActive.data, ' got active');
    }
    const fetchAnalyticsFilterData = async () => {
      const resAnalyticsFilterData = await axios.get(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_KEY}/analyticsfilter/${institute_id}`, {
        params: {
          fromDate: trackNavbarFilter.date.fromDate,
          toDate: trackNavbarFilter.date.toDate
        }
      });
      // console.log(resAnalyticsFilterData.data, " got the analytics Filter DB");
      const resActiveNonActiveFilter = await axios.get(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_KEY}/analyticsfilteractivenonactive/${institute_id}`, {
        params: {
          fromDate: trackNavbarFilter.date.fromDate,
          toDate: trackNavbarFilter.date.toDate
        }
      });
      setActiveNonActive(resActiveNonActiveFilter.data);
      // console.log(resActiveNonActiveFilter.data, ' got active filter');
      setAnalytics(resAnalyticsFilterData.data);
    }
    if(trackNavbarFilter.length == 0) fetchAnalyticsData();
    else fetchAnalyticsFilterData();
  }, [trackNavbarFilter]);

  /* Active & Non-Active Students */
  

  const statistics = [
    { label: 'Active Student', value: activeNonActive?.activeStudents ?? 0 },
    { label: 'Non-active Student', value: activeNonActive?.nonActiveStudents ?? 0 },
    { label: 'Enrolled Student', value: analytics?.total_students_enrolled ?? 0 }
  ];
  // console.log(trackNavbarFilter, " analytics filter track"); // {from-date: '2024-08-07', to-date: '2024-08-08'}

  /* ChartJs */
  const data1 = {
    labels: ['Enrolled', 'Non Enrolled'],
    datasets: [{
      label: 'Poll',
      data: [analytics.total_students_enrolled, analytics.total_students_UnEnrolled],
      backgroundColor: ['rgba(247, 152, 29, 0.82)', 'rgba(189, 214, 236, 1)']
    }]
  };
  const options1 = {
    aspectRatio: 1,
    responsive: true,
    maintainAspectRatio: false,
  }

  /* Bar Graph */
  const months = [
    "January", "February", "March", "April", "May", "June", 
    "July", "August", "September", "October", "November", "December"
  ];
  let month_date_registered_bargraph = [], date_registered_bargraph = [], studentCount_registered_bargraph = [];

  let month_date_enrolled_bargraph = [], date_enrolled_bargraph = [], studentCount_enrolled_bargraph = [];
  
  let month_date_active_bargraph = [], date_active_bargraph = [], studentCount_active_bargraph = [];
  
  let month_date_earnings_linegraph = [], date_earnings_linegraph = [], amt_earnings_linegraph = [];

  /* Registered */
  analytics.bar_registered?.map(obj => {
    const date = obj.created_at?.split('T')[0].split('-');
    if(date === undefined) return;
    const yr = date[0], mth = parseInt(date[1]), dt = parseInt(date[2]);
    month_date_registered_bargraph.push(`${dt} ${months[mth - 1]}`);
    date_registered_bargraph.push(dt);
    studentCount_registered_bargraph.push(obj.total_registered_students_byDate);
  });

  /* Enrolled */
  analytics.bar_enrolled?.map(obj => {
    const date = obj.created_at?.split('T')[0].split('-');
    if(date === undefined) return;
    const yr = date[0], mth = parseInt(date[1]), dt = parseInt(date[2]);
    month_date_enrolled_bargraph.push(`${dt} ${months[mth - 1]}`);
    date_enrolled_bargraph.push(dt);
    studentCount_enrolled_bargraph.push(obj.total_enrolled_students_byDate);
  });

  /* Active */
  const dateVsNoOfStudents = new Map();
  /* activeNonActive.month_date_ActiveStudentCount?.map(obj => {
    const date = obj.year_m.split('T')[0].split('-');
    const yr = date[0], mth = parseInt(date[1]), dt = parseInt(date[2]);
    // console.log(yr, mth, dt);
    month_date_active_bargraph.push(`${dt} ${months[mth - 1]}`);
    date_active_bargraph.push(dt);
    studentCount_active_bargraph.push(obj.student_count);
  }); */
  // for(const [key, value] of Object.entries(activeNonActive.month_date_ActiveStudentCount)){
  //   let date = key.split('-');
  //   const yr = date[0], mth = parseInt(date[1]), dt = parseInt(date[2]);
  //   month_date_active_bargraph.push(`${dt} ${months[mth - 1]}`);
  //   studentCount_active_bargraph.push(value);
  // }
  console.log(activeNonActive?.month_date_ActiveStudentCount, " active non active");
  if (activeNonActive) {
    Object.entries(activeNonActive.month_date_ActiveStudentCount).map(([key, value]) => {
      // console.log(key, value, " key value");
      let date = key.split('-');
      if(date === undefined) return;
      const yr = date[0], mth = parseInt(date[1]), dt = parseInt(date[2]);
      month_date_active_bargraph.push(`${dt} ${months[mth - 1]}`);
      studentCount_active_bargraph.push(value);
    });
  }
  

  /* Earnings */
  let netInstiEarnings = 0;
  analytics.earnings?.map(obj => {
    const date = obj.year_m.split('T')[0].split('-');
    const yr = date[0], mth = parseInt(date[1]), dt = parseInt(date[2]);
    // console.log(yr, mth, dt);
    month_date_earnings_linegraph.push(`${dt} ${months[mth - 1]}`);
    date_earnings_linegraph.push(dt);
    amt_earnings_linegraph.push(obj.transactions);
    netInstiEarnings += obj.transactions;
  });
  // console.log(year_month_enrolled_bargraph, " stud active bar");

  /* Registered */
  const data2 = {
    labels: date_registered_bargraph,
    datasets: [
      {
        label: 'Registered',
        data: studentCount_registered_bargraph,
        backgroundColor: 'rgba(189, 214, 236, 1)',
        borderWidth: 1
      },
      {
        label: 'Enrolled',
        data: studentCount_enrolled_bargraph,
        backgroundColor: 'rgba(247, 152, 29, 0.82)',
        borderWidth: 1
      },
      {
        label: 'Active',
        data: studentCount_active_bargraph,
        backgroundColor: 'rgba(217, 226, 118, 1)',
        borderWidth: 1
      }
    ]
  }
  const options2 = {
    aspectRatio: 1,
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        callbacks: {
          title: function(tooltipItems) {
            const index = tooltipItems[0].dataIndex;
            return month_date_registered_bargraph[index]; // Custom tooltip title based on your array
          }
        },
        backgroundColor: '#000000', // Black background
        titleColor: '#FFFFFF', // White text color for title
        bodyColor: '#FFFFFF', // White text color for body
        displayColors: true // Show color box in the tooltip
      },
      legend: {
        labels: {
          color: 'black'
        }
      }
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Date',
          color: 'black'
        },
        ticks: {

        },
        grid: {
          color: 'transparent',
        }
      },
      y: {
        title: {
          display: true,
          text: 'Student',
          color: 'black'
        },
        ticks: {

        },
      },
    },
    layout: {
      padding: {
        left: 16,
        right: 16,
        top: 10,
        bottom: 10,
      },
    },
  }

  /* Line Graph */
  /* let year_month_linegraph = [];
  let earning_linegraph = [];
  analytics.earnings?.map(obj => {
    year_month_linegraph.push(obj.year_m);
    earning_linegraph.push(obj.transactions);
  }); */
  const data3 = {
    labels: date_earnings_linegraph,
    datasets: [
      {
        label: 'Earnings',
        data: amt_earnings_linegraph,
        backgroundColor: 'rgba(54, 162, 235, 0.2)',
        borderColor: 'rgba(54, 162, 235, 1)',
        borderWidth: 1,
        fill: true, // Fill the area under the line
        pointRadius: 5, // Hide the points on the line
        tension: 0.4, // Make the line smooth
        borderDash: [5, 5], // Dotted lines
      },
    ],
  };
  const options3 = {
    aspectRatio: 1,
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        callbacks: {
          title: function(tooltipItems) {
            const index = tooltipItems[0].dataIndex;
            return month_date_earnings_linegraph[index]; // Custom tooltip title based on your array
          }
        },
        backgroundColor: '#000000', // Black background
        titleColor: '#FFFFFF', // White text color for title
        bodyColor: '#FFFFFF', // White text color for body
        displayColors: true // Show color box in the tooltip
      },
      legend: {
        labels: {
          color: 'white'
        }
      }
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Date',
          color: 'white'
        },
        ticks: {
          color: 'white'
        },
        grid: {
          color: 'white',
          lineWidth: 0.2
        }
      },
      y: {
        title: {
          display: true,
          text: 'Count',
          color: 'white'
        },
        ticks: {
          color: 'white'
        },
        grid:{
          color: 'white',
          lineWidth: 0.2
        }
      },
    },
    layout: {
      padding: {
        left: 16,
        right: 16,
        top: 10,
        bottom: 10,
      },
    },
  }

  return (
    <>
      <Navbar setTemp={() => { }} />
      <SidenavLeft />

      {/* Analytics Section w/ EarningIcons */}
      <div style={{ display: 'flex', justifyContent: 'space-between', margin: '34px 300px 52px 132px', alignItems: 'center' }}>
        <div style={{ fontFamily: 'Roboto', fontWeight: '500', fontSize: '24px', lineHeight: '28.13px' }}>Analytics</div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{
            border: '2px solid #313C63',
            display: 'flex',
            justifyContent: 'space-around',
            borderRadius: '8px',
            width: '230px',
            height: '40px',
            alignItems: 'center',
            textAlign: 'center',
            fontSize: '16px',
            fontWeight: '400',
            marginRight: '12px',
          }}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '8px' }}>
              <Earnings />
              <p>Earnings</p>
            </div>
            <p style={{fontWeight: 'bold'}}>{netInstiEarnings}</p>
          </div>
        </div>
      </div>


      {/* Grid View analytics Section (2x2) */}
      <div className={styles.analyticsContainer}>

        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '', height: '280px' }}>
          <Doughnut
            data={data1}
            options={options1}
            style={{marginLeft: ''}}
          >
          </Doughnut>
        </div>

        <div className={styles.barLineChart}>
          <Bar
            data={data2}
            options={options2}
          >
          </Bar>
        </div>

        <div className={styles.registered}>
          <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: '6.13px', fontSize: '22px' }}>
            <RegistersIcon />
            <div>Registered</div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', fontSize: '20px' }}>
            <div>Number of Students Registered</div>
            <div style={{ fontWeight: 'bold' }}>{analytics.total_students_registered}</div>
          </div>
          <div className={styles.statisticsContainer}>
            {statistics.map((stat, index) => (
              <React.Fragment key={stat.label}>
                <div className={styles.statisticItem}>
                  {stat.label.split(' ').map((word, index) => (
                    <React.Fragment key={index}>
                      {word}
                      <br />
                    </React.Fragment>
                  ))}
                </div>
                <div className={styles.statisticValue}>{stat.value}</div>
                {index < statistics.length - 1 && <div className={styles.divider} />}
              </React.Fragment>
            ))}
          </div>
        </div>

        <div className={styles.LineChart}>
          <Line data={data3} options={options3}>

          </Line>
        </div>

      </div>

      {/* sideNav-Right | Filters */}
      <TimelineSidenavRight setTrackNavbarFilter = {setTrackNavbarFilter} />
    </>
  )
}

export default Analytics