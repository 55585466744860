import React from 'react';
import styles from './DashboardMobile.module.css';

/* Icons */
import { ReactComponent as NavBarHamburgerIcon } from '../../assets/DashboardIcons/NavBarHamburgerIcon.svg';
import { ReactComponent as TooltipIcon } from '../../assets/DashboardIcons/TooltipIcon.svg';
import { ReactComponent as FilterIcon } from '../../assets/DashboardIcons/FilterIcon.svg';

const DashboardMobile = ({ userDetails }) => {
  const skills = ["Computational Skills", "Gods Speed"]; // Many more skills can be included in future...
  return (
    <>
      <hr style={{ marginTop: '2.5rem', border: '1px solid rgba(49, 60, 96, 1)' }} />
      <div className={styles.navbar}>
        <NavBarHamburgerIcon style={{ cursor: 'pointer' }} />
        <div className={styles.timelineFilter}>
          <button className={styles.buttonStyle}>
            Timeline
            <TooltipIcon className={styles.svgStyle} />
            <div className={styles.tooltip}>Check History</div>
          </button>
          <FilterIcon style={{ cursor: 'pointer' }} />
        </div>
      </div>

      {/* UserDetails */}
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '1.25rem 0 0.813rem' }}>
        <div style={{ fontWeight: '500', fontSize: '18px' }}>{userDetails[0]?.name}</div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{
            fontSize: '10px',
            border: '2px solid rgba(49, 60, 99, 1)',
            display: 'flex',
            gap: '0.75rem',
            padding: '0.5rem',
            height: '1.75rem',
            justifyContent: 'space-around',
            borderRadius: '8px',
            alignItems: 'center',
            textAlign: 'center'
          }}>
            <p className={styles.pMobile}>{skills[userDetails[0]?.skill_id - 1]}</p>
            <p className={styles.pMobile}>Stage {userDetails[0]?.stage_id}</p>
            <p className={styles.pMobile}>{userDetails[0]?.accuracy}%</p>
          </div>
        </div>
      </div>

      {/* Last Active Day & Today */}
      <div className="displayGrid">
        <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr', columnGap: '0.25rem'}}>
          <div className={styles.dayColumn}>Last Active Day</div>
          <div className={styles.dayColumn}>Today</div>
        </div>
      </div>
    </>
  )
}

export default DashboardMobile