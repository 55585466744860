import { useState } from "react";

import {
    AppBar,
    Button,
    Checkbox,
    Container,
    FormControl,
    FormControlLabel,
    IconButton,
    InputLabel,
    Link,
    OutlinedInput,
    TextField,
    Toolbar,
    Typography,
    InputAdornment
} from "@mui/material";
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import SmartsaLogo from '../../../assets/DashboardIcons/smartsaLogo.svg'
import SmartsaIcon from '../../../assets/auth-icons/smartsa_icon.svg';

import styles from './Login.module.css'
import SignupCarousel from "../../Common/FooterCarousel/FooterCarousel";
import ImageCarousel from "../../Common/ImageCarousel/ImageCarousel";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import isDesktop from "../../../utils/isDesktop";

const Login = () => {
    // console.log("Hello World", isDesktop);
    const isDesktopTrue = isDesktop();
    const [showPassword, setShowPassword] = useState(false);
    const [email, setInstituteEmail] = useState("")
    const [password, setPassword] = useState("");
    const [rememberMe, setRememberMe] = useState(false);
    const [message, setMessage] = useState("")
    const navigate = useNavigate()
    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleSubmit = async (e) => {
        e.preventDefault(); 
        console.log("Hello Wiorld");
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_KEY}/institute/login`, {
                identifier: email,
                password,
            });

            const { token, instituteEmail, instituteId } = response.data;
            localStorage.setItem("token", token);
            localStorage.setItem("institute_email", instituteEmail);
            localStorage.setItem("institute_id", instituteId);

            setInstituteEmail("");
            setPassword("");
            setRememberMe(false);
            setMessage("");
            navigate("/dashboard");
        } catch (error) {
            if (error.response && error.response.data) {
                setMessage(error.response.data.message)
                console.error("Error logging in:", error.response.data.message);
            } else {
                setMessage("An error occurred. Please try again later.");
                console.error("Error logging in:", error.message);
            }
        }
    };

    return (
        <div>
            {
                isDesktopTrue ? (
                    <>
                        <hr style={{border: '1px solid rgba(49, 60, 96, 1)'}} />
                        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: '10px', margin: '0px 12px'}}>
                            <a href="/aboutus" style={{textDecoration: 'none', color: 'black'}}>About Us</a>
                            <a href="/help" style={{textDecoration: 'none', color: 'black'}}>Help</a>
                        </div>
                    </>
                ) :
                (
                    <AppBar position="static" sx={{ background: '#EAF1F7', borderRadius: '8px' }}>
                        <Container maxWidth="xl">
                            <Toolbar disableGutters sx={{ display: 'flex', justifyContent: 'space-between', }}>
                                <a href="/">
                                    <img
                                        src={SmartsaLogo}
                                        alt="Company Logo"
                                        className={styles.logoStyle}
                                    />
                                </a>
                                <div className={styles.headerLinks}>
                                    <Typography>About Us</Typography>
                                    <Typography>Help</Typography>
                                </div>
                            </Toolbar>
                        </Container>
                    </AppBar>
                )
            }
            <div className={isDesktopTrue ? `${styles.wrapperMobile}` : `${styles.wrapper}`}>
                <Container maxWidth='xl' className={isDesktopTrue ? `${styles.containerMobile}` : `${styles.container}`}>
                    <div className={isDesktopTrue ? `${styles.formContainerMobile}` : `${styles.formContainer}`}>
                        <Typography align="center" className={styles.IconContainer} style={isDesktopTrue ? {marginBottom: '5.063rem'} : {marginBottom: '0'}}>
                            <img src={SmartsaIcon} height={100} alt="Smartsa Logo" />
                        </Typography>
                        <form
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: isDesktopTrue ? "12px" : "0.625vw",
                            }}
                            onSubmit={handleSubmit}
                        >
                            <TextField
                                required
                                type="email"
                                label="Username"
                                variant="outlined"
                                className={isDesktopTrue ? `${styles.customTextfieldMobile}` : `${styles.customTextfield}`}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: 'transparent',
                                        },
                                        '&:hover fieldset': {
                                            borderColor: 'transparent',
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: 'transparent',
                                        },
                                    },
                                }}
                                value={email}
                                onChange={(e) => setInstituteEmail(e.target.value)}
                            />
                            <FormControl variant="outlined"
                                className={isDesktopTrue ? `${styles.customTextfieldMobile}` : `${styles.customTextfield}`}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: 'transparent',
                                        },
                                        '&:hover fieldset': {
                                            borderColor: 'transparent',
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: 'transparent',
                                        },
                                    },
                                }}
                            >
                                <InputLabel htmlFor="outlined-adornment-password">
                                    Password
                                </InputLabel>
                                <OutlinedInput
                                    required
                                    id="outlined-adornment-password"
                                    type={showPassword ? "text" : "password"}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                                style={{ backgroundColor: "transparent", color: "#000" }}
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="Password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </FormControl>
                            <div className={styles.errorMessage}>
                                {message}
                            </div>
                            <div className={styles.subTextCont}>
                                <FormControlLabel
                                    className={`${styles["remember-me-checkbox"]}`}
                                    control={
                                        <Checkbox
                                        />
                                    }
                                    label="Remember me"
                                />
                                <Link
                                    href="/forgot-password"
                                    variant="body2"
                                    className={isDesktopTrue ? `${styles.linkMobile}` : `${styles.link}`}
                                    style={{ color: '#000' }}
                                >
                                    Forgot password?
                                </Link>
                            </div>
                            <Button
                                type="submit"
                                variant="contained"
                                fullWidth
                                className={isDesktopTrue ? `${styles.loginButtonMobile}` : `${styles.loginButton}`}
                            >
                                Login
                            </Button>
                            <span className={styles.subText}>
                                <p style={{ fontSize: isDesktopTrue ? '16px' : '0.897vw', color: 'black' }}>Not an user?</p>
                                <Link href="/signup" variant="body2" className={isDesktopTrue ? `${styles.linkMobile}` : `${styles.link}`} style={{ color: '#F68D14' }}>
                                    Signup
                                </Link>
                            </span>
                        </form>
                    </div>
                    {
                        !isDesktopTrue && (
                            <div className={styles.carousel}>
                                <ImageCarousel />
                            </div>
                        )
                    }
                </Container>
            </div>
            <SignupCarousel className={styles.SignupCarouselMobile} />
        </div>
    )
}

export default Login;