import { useEffect, useState } from "react";
import axios from "axios";
import {
    AppBar,
    Container,
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    TextField,
    Toolbar,
    Typography,
    Button,
    Link,
    MenuItem,
    Grid,
} from "@mui/material";
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import { toast } from "react-toastify";

import SmartsaLogo from '../../../assets/DashboardIcons/smartsaLogo.svg'
import SmartsaIcon from '../../../assets/auth-icons/smartsa_icon.svg';

import styles from './Signup.module.css';
import SignupCarousel from "../../Common/FooterCarousel/FooterCarousel";
import { useNavigate } from "react-router-dom";
import VerificationDialog from "../../Common/Verification/VerificationDialog";

const instituteList=["School","College","NGO","Institute"]

const Signup = () => {
    const [userData, setUserData] = useState({
        name: "",
        email: "",
        mobile: "",
        password: "",
        address: "",
        country: "",
        city: "",
        state: "",
        pincode: "",
        institute_type: "",
      });
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [error, setError] = useState("");
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const navigate = useNavigate();
    const [openVerificationDialog, setOpenVerificationDialog] = useState(false);
    const [usernameExists, setUsernameExists] = useState(false);
    const [incorrectPassword, setIncorrectPassword] = useState();
    const [mobileErrorMsg, setMobileErrorMsg] = useState("");

    useEffect(() => {
        setUserData(prev => {
            return {
                ...prev,
            }
        })
    })

    const handleClickShowConfirmPassword = () =>
        setShowConfirmPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (userData.mobile.length !== 10) {
            setMobileErrorMsg("Please Enter a Valid Mobile Number");
            return;
        }
        console.log("Form Data: ", userData);

        if (userData.password === userData.confirmPassword) {
            setOpenVerificationDialog(true);
        } else {
            setIncorrectPassword(
                <p
                    style={{
                        color: "#ff0000",
                        paddingLeft: "1rem",
                        fontSize: "0.8125rem",
                    }}
                >
                    *Passwords don't match !
                </p>
            );
        }
    };

      const onChangeName = (event) => {
        setUserData((prevData) => {
          return { ...prevData, name: event.target.value };
        });
      };
    
      const onChangeEmail = (event) => {
        const lowerCaseEmail = event.target.value.toLowerCase(); // Convert email to lowercase
        setUserData((prevData) => {
          return { ...prevData, email: lowerCaseEmail };
        });
      };
    
      const onChangeMobile = (event) => {
        setUserData((prevData) => {
          return { ...prevData, mobile: event.target.value };
        });
      };

      const onChangeAddress = (event) => {
        setUserData((prevData) => {
          return { ...prevData, address: event.target.value };
        });
      };
    
      const onChangeCountry = (event) => {
        setUserData((prevData) => {
          return { ...prevData, country: event.target.value };
        });
      };

      const onChangeState = (event) => {
        setUserData((prevData) => {
          return { ...prevData, state: event.target.value };
        });
      };

      const onChangeCity = (event) => {
        setUserData((prevData) => {
          return { ...prevData, city: event.target.value };
        });
      };
    
      const onChangePassword = (event) => {
        setUserData((prevData) => {
          return { ...prevData, password: event.target.value };
        });
      };
    
      const onChangeConfirmPassword = (event) => {
        setUserData((prevData) => {
          return { ...prevData, confirmPassword: event.target.value };
        });
      };
    
      const onChangePincode = (event) => {
        setUserData((prevData) => {
          return { ...prevData, pincode: event.target.value };
        });
      };

      const onChangeInstitute = (event) => {
        setUserData((prevData) => {
          return { ...prevData, institute_type: event.target.value };
        });
      };

    return (
        <>
            {openVerificationDialog ? (
                <div>
                    <VerificationDialog email={userData.email} name= {userData.name} userData ={userData} setOpenVerificationDialog= {setOpenVerificationDialog} />
                </div>
            ) : (
        <div>
            <AppBar position="static" sx={{ background: '#EAF1F7', borderRadius: '8px' }}>
                <Container maxWidth="xl">
                    <Toolbar disableGutters sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <a href="/">
                            <img
                                src={SmartsaLogo}
                                alt="Company Logo"
                                style={{ display: 'flex', marginRight: '16px', height: '36px', width: '197.56px' }}
                            />
                        </a>
                        <div className={styles.headerLinks}>
                            <Typography>About Us</Typography>
                            <Typography>Help</Typography>
                        </div>
                    </Toolbar>
                </Container>
            </AppBar>
            <Container maxWidth="sm" className={styles.signupContainer}>
                <Typography align="center" className={styles.IconContainer}>
                    <img src={SmartsaIcon} height={100} alt="Smartsa Logo" />
                </Typography>
                <form onSubmit={handleSubmit} className={styles.formContainer}>
                <div className={styles.fieldsContainer} >
                    <div className={styles.leftfields}>
                        <TextField
                            type="text"
                            label="Name"
                            // variant="outlined"
                            fullWidth
                            className={styles.customTextfield}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: 'transparent',
                                    },
                                    '&:hover fieldset': {
                                        borderColor: 'transparent',
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: 'transparent',
                                    },
                                },
                            }}
                            value={userData.name}
                            onChange={onChangeName}
                            />
                        <TextField
                            type="email"
                            label="Email"
                            variant="outlined"
                            fullWidth
                            className={styles.customTextfield}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: 'transparent',
                                    },
                                    '&:hover fieldset': {
                                        borderColor: 'transparent',
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: 'transparent',
                                    },
                                },
                            }}
                            value={userData.email}
                            onChange={onChangeEmail}
                        />
                        <TextField
                            type="tel"
                            label="Mobile"
                            variant="outlined"
                            fullWidth
                            className={styles.customTextfield}
                                                        inputProps={{
                                pattern: "[0-9]{10}",
                                title: "Mobile number must be 10 digits",
                            }}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: 'transparent',
                                    },
                                    '&:hover fieldset': {
                                        borderColor: 'transparent',
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: 'transparent',
                                    },
                                },
                            }}
                            value={userData.mobile}
                            onChange={onChangeMobile}
                        />
                                        {mobileErrorMsg.length > 0 && (
                                            <p
                                                style={{
                                                    color: "#ff0000",
                                                    paddingLeft: ".5rem",
                                                    fontSize: "0.8125rem",
                                                }}
                                            >
                                                *{mobileErrorMsg}
                                            </p>
                                        )}
                        <TextField
                            id="insitute-type"
                            select
                            label="Institute Type"
                            defaultValue=""
                            value={userData.institute_type}
                            required
                            onChange={onChangeInstitute}
                            className={styles.customTextfield}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: 'transparent',
                                    },
                                    '&:hover fieldset': {
                                        borderColor: 'transparent',
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: 'transparent',
                                    },
                                },
                            }}
                        >
                            {instituteList.map((option) => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </TextField>
                        <TextField
                            label="Address"
                            variant="outlined"
                            fullWidth
                            className={styles.customTextfield}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: 'transparent',
                                    },
                                    '&:hover fieldset': {
                                        borderColor: 'transparent',
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: 'transparent',
                                    },
                                },
                            }}
                            value={userData.address}
                            onChange={onChangeAddress}
                        />
                        <TextField
                            label="Country"
                            variant="outlined"
                            fullWidth
                            className={styles.customTextfield}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: 'transparent',
                                    },
                                    '&:hover fieldset': {
                                        borderColor: 'transparent',
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: 'transparent',
                                    },
                                },
                            }}
                            value={userData.country}
                            onChange={onChangeCountry}
                        />
                    </div>
                    <div className={styles.rightFields}>
                        <TextField
                            type="text"
                            label="State"
                            variant="outlined"
                            fullWidth
                            className={styles.customTextfield}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: 'transparent',
                                    },
                                    '&:hover fieldset': {
                                        borderColor: 'transparent',
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: 'transparent',
                                    },
                                },
                            }}
                            value={userData.state}
                            onChange={onChangeState}
                        />
                        <TextField
                            type="text"
                            label="City"
                            variant="outlined"
                            fullWidth
                            className={styles.customTextfield}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: 'transparent',
                                    },
                                    '&:hover fieldset': {
                                        borderColor: 'transparent',
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: 'transparent',
                                    },
                                },
                            }}
                            value={userData.city}
                            onChange={onChangeCity}
                        />
                        <TextField
                            type="text"
                            label="Pin Code"
                            variant="outlined"
                            fullWidth
                            className={styles.customTextfield}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: 'transparent',
                                    },
                                    '&:hover fieldset': {
                                        borderColor: 'transparent',
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: 'transparent',
                                    },
                                },
                            }}
                            value={userData.pincode}
                            onChange={onChangePincode}
                        />
                        <FormControl
                            className={styles.customTextfield}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: 'transparent',
                                    },
                                    '&:hover fieldset': {
                                        borderColor: 'transparent',
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: 'transparent',
                                    },
                                },
                            }}
                            variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password">
                                Password
                            </InputLabel>
                            <OutlinedInput
                                required
                                id="outlined-adornment-password"
                                type={showPassword ? "text" : "password"}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                            style={{
                                                backgroundColor: "transparent",
                                                color: "#000",
                                            }}
                                        >
                                            {showPassword ? (
                                                <VisibilityOff />
                                            ) : (
                                                <Visibility />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="Password"
                                value={userData.password}
                                onChange={onChangePassword}
                            />
                        </FormControl>
                        <FormControl
                            className={styles.customTextfield}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: 'transparent',
                                    },
                                    '&:hover fieldset': {
                                        borderColor: 'transparent',
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: 'transparent',
                                    },
                                },
                            }}
                            variant="outlined" >
                            <InputLabel htmlFor="outlined-adornment-confirm-password">
                                Confirm Password
                            </InputLabel>
                            <OutlinedInput
                                required
                                id="outlined-adornment-confirm-password"
                                type={showConfirmPassword ? "text" : "password"}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle confirm password visibility"
                                            onClick={handleClickShowConfirmPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                            style={{
                                                backgroundColor: 'transparent',
                                                color: "#000",
                                            }}
                                        >
                                            {showConfirmPassword ? (
                                                <VisibilityOff />
                                            ) : (
                                                <Visibility />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="Confirm Password"
                                value={userData.confirmPassword}
                                onChange={onChangeConfirmPassword}
                            />
                        </FormControl>
                    </div>
                </div>
                    <div className={styles.buttonContainer}>
                        <Button className={styles.submitButton} type="submit">
                            Submit
                        </Button>
                        <div className={styles.subText}>
                            <p>Existing User?</p>
                            <Link href="/login" variant="body2" className={styles.link} style={{ color: '#F68D14' }}>
                                Login
                            </Link>
                        </div>
                    </div>
                </form>
                {incorrectPassword}
                  {usernameExists && (
                    <Grid item xs={12}>
                      <p style={{ color: "red", fontSize: "0.8125rem" }}>
                        *Username already exists!
                      </p>
                    </Grid>
                  )}

            </Container>
            <SignupCarousel />
        </div>
      )}
        </>
    )
}

export default Signup;