import React from 'react'
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import './App.css';

import Dashboard from './components/Dashboard/Dashboard';
import TeacherProfile from './components/TeacherProfile/TeacherProfile';
import Timeline from './components/Timeline/Timeline';
import Home from './Home';
import StudentList from './components/StudentList/StudentList';
import AccountDetails from './components/AccountDetails/AccountDetails';
import Analytics from './components/Analytics/Analytics';
import Signup from './components/Auth/Signup/Signup';
import Login from './components/Auth/Login/Login'
import HelpPage from './components/HelpPage/HelpPage';
import HowItWorks from './components/HowItWorks/HowItWorks';
import AboutUs from './components/AboutUs/AboutUs';

const App = () => {
  return (
    <Routes>
      <Route path='/' element={<Login />} />
      <Route path='/timeline/:user_id' element={<Timeline />} />
      <Route path='/teacherprofile' element={<TeacherProfile />} />
      <Route path='/dashboard' element={<StudentList />} /> {/* Student List */}
      <Route path='/accountdetails' element={<AccountDetails />} />
      <Route path='/analytics' element={<Analytics />} />
      <Route path="/studentprofile/:user_id" element={<Dashboard />} />
      <Route path="/signup" element={<Signup />} />
      {/* <Route path="/login" element={<Login />} /> */}
      <Route path="/help" element={<HelpPage />} />
      <Route path="/how-it-works" element={<HowItWorks />} />
      <Route path="/about-us" element={<AboutUs />} />
    </Routes>
  )
}

export default App;