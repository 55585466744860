import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from "@mui/material";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import styles from "./VerificationDialog.module.css";

const VerificationDialog = ({ email, name, userData, setOpenVerificationDialog }) => {
  const [open, setOpen] = useState(true);
  const [otpCode, setOtpCode] = useState("");
  const [verificationMessage, setVerificationMessage] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [countdown, setCountdown] = useState(30);
  const [otpButtonColor, setOtpButtonColor] = useState({
    backgroundColor: "#313C63",
    color: "#fff",
    fontSize: "0.8125rem"
  });
  const [confirmButtonColor, setConfirmButtonColor] = useState({
    backgroundColor: "#313C63",
    color: "#fff",
    textTransform: "capitalize",
    fontSize: "0.8125rem"
  });
  const [OtpMessage, setOtpMessage] = useState("");
  const navigate = useNavigate();

  const handleClose = () => {
    setOpen(false);
    setOpenVerificationDialog(false)
  };

  useEffect(() => {
    handleGetOTP()
  }, [userData.name])

  useEffect(() => {
    let timer;
    if (isDisabled) {
      timer = setTimeout(() => {
        setCountdown(prevCountdown => {
          if (prevCountdown === 1) {
            setIsDisabled(false);
            return 0;
          } else {
            return prevCountdown - 1;
          }
        });
      }, 1000);
    }
    return () => clearTimeout(timer);
  }, [isDisabled, countdown]);

  const handleGetOTP = () => {
    const requestData = {
      name: userData.name,
      email: email,
    };

    const apiUrl = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_KEY}/generate-otp`;

    console.log("API URL:", apiUrl);

    axios
      .post(apiUrl, requestData)
      .then((response) => {
        setVerificationMessage(response.data.message);
        setOtpButtonColor({
          backgroundColor: "#313C6350",
          color: "#000",
          textTransform:"capitalize"
        });
        setConfirmButtonColor({
          backgroundColor: "#313C63",
          color: "#fff",
          textTransform:"capitalize"
        });
        setOtpMessage("Resend Otp");
        setIsDisabled(true);
        setCountdown(30);
      })
      .catch((error) => {
        console.error("Error generating OTP:", error);
        setVerificationMessage("Error generating OTP");
      });
  };

  const handleValidateOTP = (e) => {
    if (otpCode.length !== 6) {
      setVerificationMessage("OTP must be 6 digits long");
      return;
    }
    axios
      .put(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_KEY}/generate-otp/validate-otp/${userData.name}`, {
        otp: otpCode,
      })
      .then((response) => {
        console.log("OTP validation successful:", response.data);
        setVerificationMessage("Signup Successful");
        handleSubmit();
      })
      .catch((error) => {
        console.error("Error validating OTP:", error);
        setVerificationMessage(error.response.data.message);
      });
  };

  const handleSubmit = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_KEY}/institute/add`,
        userData
      );

      const { token, instituteId } = response.data;
      localStorage.setItem("token", token);
      localStorage.setItem("institute_id", instituteId);
      navigate("/dashboard");
    } catch (error) {
      console.error("Error registering user:", error.response.data.message);
    }
  };

  const formatTime = seconds => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{ '& .MuiPaper-root': { background: '#fff' }, }}
      >
        <DialogTitle style={{ color: "#000" }}>
          Verify your Email!!
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="otp"
            label="Enter OTP Code"
            type="text"
            fullWidth
            value={otpCode}
            onChange={(e) => setOtpCode(e.target.value)}
            className={styles.dialogField}
          />
          <div style={{ color: "green" }}>{verificationMessage}</div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleGetOTP} style={otpButtonColor} disabled={isDisabled}>
            {isDisabled ? `Resend OTP in ${formatTime(countdown)}` : 'Resend OTP'}
          </Button>
          <Button onClick={handleValidateOTP} style={confirmButtonColor}>
            Confirm
          </Button>
          <Button
            onClick={handleClose}
            color="error"
            variant="contained"
            style={{textTransform:'capitalize', color:"#fff", }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default VerificationDialog;
