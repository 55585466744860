import YouTube from "react-youtube";
import Wrapper from "../WrapperDesktop/Wrapper";
import styles from "./HowItWorks.module.css"

const HowItWorks = () => {

    const opts = {
        height: 480,
        width: 854,
        playerVars: {
            autoplay: 1,
        },
    };

    return (
        <>
            <Wrapper />
            <div className={styles.pageWrapper}>
                <div className={styles.pageHeader}>How It Works</div>
                <div className={styles.pageContent}>
                    <div className={styles.leftContent}>
                        <div className={styles.titleStyle}>What is SMARTSA?</div>
                        <div className={styles.textStyle}>SMARTSA is a digital practice book which improves the speed, accuracy and previous concepts (Backlogs) in Mathematics.
                        </div>
                    </div>
                    <div className={styles.rightContent}>
                        <YouTube videoId="m3VzpmhwNdE" opts={opts} className={styles.youtubePlayer} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default HowItWorks;