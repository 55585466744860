import React, { useEffect, useRef, useState } from 'react'
import Wrapper from '../WrapperDesktop/Wrapper'
import VerificationDialogTeacher from '../Common/Verification/VerificationDialogTeacher';
import styles from './TeacherProfile.module.css'
import axios from 'axios';

const TeacherProfile = () => {
  const institute_id = localStorage.getItem('institute_id');
  const [isEdit, setIsEdit] = useState(false);
  const [temp2, setTemp2] = useState(null); /* Details of teachers is saved in this variable */

  /* Profile Upload */
  const [profileImage, setProfileImage] = useState();
  const fileInputRef = useRef(null);

  /* OTP Verification */
  const [openVerificationDialog, setOpenVerificationDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [isValidationCorrect, setIsValidationCorrect] = useState(false);

  /* Teacher Details Update */
  const [email, setEmail] = useState('');
  const [country, setCountry] = useState('');
  const [mobile, setMobile] = useState('');
  const [state, setState] = useState('');
  const [institution, setInstitution] = useState('');
  const [city, setCity] = useState('');
  const [username, setUsername] = useState('');
  const [pincode, setPincode] = useState('');
  const [profileUrl, setProfileUrl] = useState('');
  const updatedDetails1 = {
    email,
    country,
    mobile,
    state,
    institution,
    city,
    username,
    pincode
  };
  // console.log(temp2, "Hello World---------------------------");

  const setValuesFromProp = () => {
    // console.log(institute_id);
    // console.log(temp2, " Teacher DATA");
    // console.log(temp2?.email);
    setEmail(temp2?.institute_email);
    setCountry(temp2?.country);
    setMobile(temp2?.institute_mobile_no);
    setState(temp2?.institute_state);
    setInstitution(temp2?.institute_type);
    setCity(temp2?.institute_city);
    setUsername(temp2?.institute_name);
    setPincode(temp2?.institute_pincode);
    setProfileUrl(temp2?.profile_photo_url);
  }


  useEffect(() => {
    // console.log(temp2);
    if (temp2) {
      setValuesFromProp()
      console.log(temp2);
    }
  }, [temp2])
  /* Getting Teacher Details */
  // temp2 holds the current logged-in Teacher Details
  const handleSubmit = async (event) => {
    event.preventDefault();

    /* Profile Upload */
    let imageUrlS3;
    console.log(profileImage, " got submit ProfileImage");
    if(profileImage){
      try {
        const formData = new FormData();
        formData.append("file", profileImage);
        const resProfilePhotoS3Link = await axios.post(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_KEY}/teacherprofileupload/${institute_id}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data"
            },
          }
        );
        imageUrlS3 = resProfilePhotoS3Link.data;
        setProfileUrl(imageUrlS3);
        // console.log(imageUrlS3);
      } catch (error) {
        console.log("Error uploading file:", error);
      }
    }

    // check if all the fields are filled and other conditions
    if (email && country && mobile && state && institution && city && username && pincode) {
      setOpenVerificationDialog(true);
      // console.log(isValidationCorrect, " what massage got");
      // console.log(imageUrlS3, " got profileUrl in post");
      console.log(isValidationCorrect);
      if (isValidationCorrect) {
        setOpenVerificationDialog(false);
        // console.log(event.target);
        console.log(updatedDetails1, openVerificationDialog, " got the edited details");
        const updatedDetails = {
          user_id: 1,
          email,
          country,
          mobile,
          state,
          institution,
          city,
          username,
          pincode,
          profile_photo_url: imageUrlS3
        };
        try {
          console.log(updatedDetails, " fuck u");
          await axios.post(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_KEY}/teacherprofileedit/${institute_id}`, updatedDetails);
          setIsEdit(!isEdit);
        } catch (error) {
          console.log(error);
        }
      }else{
        // setErrorMessage(<p
        //   style={{
        //     color: "#ff0000",
        //     paddingLeft: "1rem",
        //     fontSize: "0.8125rem",
        //   }}
        // >
        //   *Something went wrong!, while validating your OTP.
        // </p>);
      }
    }
    else {
      setErrorMessage(
        <p
          style={{
            color: "#ff0000",
            paddingLeft: "1rem",
            fontSize: "0.8125rem",
          }}
        >
          *All Fields are required!
        </p>
      );
    }

  }


  const handleClick = () => {
    setValuesFromProp()
    setIsEdit(false)
  }

  /* Profile Photo Handle */
  const handleFileChange = async (event) => {
    console.log("clicked");
    const file = event.target.files[0];
    console.log(file, " file got");
    setProfileImage(file);
    console.log(profileImage, " profile Image got");
    // if (file) {
    //   try {
    //     const formData = new FormData();
    //     formData.append('photos', file);

    //     // Replace with your API endpoint to upload the file
    //     const response = await axios.post('/upload', formData, {
    //       headers: {
    //         'Content-Type': 'multipart/form-data',
    //       },
    //     });

    //     console.log('File uploaded successfully', response.data);
    //   } catch (error) {
    //     console.error('Error uploading file', error);
    //   }
    // }
  };
  const handleProfileUpload = () => {
    fileInputRef.current.click();
    console.log("clicked");
  }
  return (
    <>
      <Wrapper setTemp2={setTemp2} />
      <div style={{
        display: 'flex',
        justifyContent: 'space-between',
        margin: '40px 300px 40px 132px',
        alignItems: 'center',
        fontSize: '24px',
        fontWeight: '500'
      }}>
        Profile
      </div>
      <div className={styles.container}>
        {
          profileUrl === null ?
            (
              <svg width="160" height="160" viewBox="0 0 179 179" fill="none" xmlns="http://www.w3.org/2000/svg">
                <defs>
                  <filter id="brightnessContrast">
                    <feComponentTransfer>
                      <feFuncR type="linear" slope="1.5" intercept="0" />
                      <feFuncG type="linear" slope="1.5" intercept="0" />
                      <feFuncB type="linear" slope="1.5" intercept="0" />
                    </feComponentTransfer>
                  </filter>
                </defs>
                <g clip-path="url(#clip0_230_303)" filter="url(#brightnessContrast)">
                  <path d="M150.344 9H27.656C17.3526 9 9 17.3526 9 27.656V150.344C9 160.647 17.3526 169 27.656 169H150.344C160.647 169 169 160.647 169 150.344V27.656C169 17.3526 160.647 9 150.344 9Z" fill="#313C61" />
                  <mask id="mask0_230_303" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="9" y="9" width="160" height="160">
                    <path d="M150.344 9H27.656C17.3526 9 9 17.3526 9 27.656V150.344C9 160.647 17.3526 169 27.656 169H150.344C160.647 169 169 160.647 169 150.344V27.656C169 17.3526 160.647 9 150.344 9Z" fill="white" />
                  </mask>
                  <g mask="url(#mask0_230_303)">
                    <path d="M108.112 87.792H102.848C98.5382 90.031 93.7528 91.1999 88.8961 91.1999C84.0393 91.1999 79.2539 90.031 74.9441 87.792H69.6881C63.4854 87.7983 57.5384 90.2646 53.1516 94.6499C48.7649 99.0351 46.2966 104.981 46.2881 111.184V141.512H131.504V111.184C131.496 104.983 129.028 99.0377 124.643 94.6527C120.258 90.2677 114.313 87.8005 108.112 87.792Z" fill="white" />
                    <path d="M113.496 60.792C113.496 55.9266 112.053 51.1705 109.35 47.125C106.647 43.0796 102.805 39.9265 98.3099 38.0646C93.8149 36.2027 88.8686 35.7155 84.0967 36.6647C79.3248 37.6139 74.9415 39.9568 71.5011 43.3972C68.0607 46.8376 65.7178 51.2209 64.7686 55.9928C63.8194 60.7647 64.3066 65.711 66.1685 70.206C68.0304 74.7011 71.1834 78.5431 75.2289 81.2462C79.2743 83.9493 84.0305 85.392 88.8959 85.392C95.4183 85.3857 101.672 82.7919 106.284 78.1798C110.896 73.5678 113.49 67.3144 113.496 60.792Z" fill="white" />
                  </g>
                </g>
                <defs>
                  <clipPath id="clip0_230_303">
                    <rect width="160" height="160" fill="white" transform="translate(9 9)" />
                  </clipPath>
                </defs>
              </svg>
            ) :
            (
              <img src={profileUrl} alt="" style={{
                width: '10.256vw', height: '10.256vw', borderRadius: '16px', objectFit: 'cover', boxShadow: '3.13px 3.13px 6.25px 0px rgba(89, 89, 111, 0.5), -2.08px -2.08px 6.25px 0px rgba(255, 255, 255, 1)'
              }} />
            )
        }

        {/* Profile Upload */}
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={handleFileChange}
        />
        <span style={{ fontSize: '12px', cursor: 'pointer' }} onClick={handleProfileUpload}>{isEdit && 'change'}</span>
        <div style={{ fontSize: '20px', fontWeight: '500', margin: '20px auto' }}>{username}</div>
        {openVerificationDialog &&
          <div style={{ zIndex: '8' }}>
            <VerificationDialogTeacher email={email} name={username} userData={updatedDetails1} setOpenVerificationDialog={setOpenVerificationDialog} setIsValidationCorrect={setIsValidationCorrect} setIsEdit={setIsEdit} />
          </div>
        }
        <>
          <form className={styles.detailsGrid} onSubmit={handleSubmit}>
            <input type="text" placeholder='Email' value={email} onChange={(e) => isEdit && setEmail(e.target.value)} />
            <input type="text" placeholder='Country' value={country} onChange={(e) => isEdit && setCountry(e.target.value)} />
            <input type="text" placeholder='Mobile' value={mobile} onChange={(e) => isEdit && setMobile(e.target.value)} />
            <input type="text" placeholder='State' value={state} onChange={(e) => isEdit && setState(e.target.value)} />
            <input type="text" placeholder='Institution' value={institution} onChange={(e) => isEdit && setInstitution(e.target.value)} />
            <input type="text" placeholder='City' value={city} onChange={(e) => isEdit && setCity(e.target.value)} />
            <input type="text" placeholder='Institute Username/Username' value={username} onChange={(e) => isEdit && setUsername(e.target.value)} />
            <input type="text" placeholder='Pin code' value={pincode} onChange={(e) => isEdit && setPincode(e.target.value)} />
            <span style={{ fontSize: '12px', marginTop: '-12px', cursor: 'pointer' }}>{isEdit && 'Change Password'}</span>
            {
              isEdit && (
                <div className={styles.onEdit}>
                  <button className={styles.close} onClick={() => handleClick()}>Cancel</button>
                  <button className={styles.save} type='submit'>Save</button>
                </div>
              )
            }
          </form>
          {errorMessage}
        </>
        {
          !isEdit && (
            <button style={{
              marginTop: '32px',
              cursor: 'pointer',
              height: '48px',
              width: '149px',
              fontSize: '16px',
              borderRadius: '8px',
              padding: '16px 36px 16px 36px',
              background: 'linear-gradient(116.29deg, #313C63 3.44%, rgba(40, 49, 81, 0.95) 96.13%)',
              color: 'white',
              border: 'none',
              boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.1), 0px 2px 4px 0px rgba(0, 0, 0, 0.1) inset'
            }}
              onClick={() => setIsEdit(!isEdit)}
            >
              Edit Profile
            </button>
          )
        }
      </div>
    </>
  )
}

export default TeacherProfile

/* Teacher ProfileIcon Page and Edit page both in same component */